@media (max-width: 1300px) {
    .revenue {
        margin-top: 3rem;
        margin-left: 3rem;
    }
}

@media (max-width: 1024px) {
    .revenue {
        margin-left: 8rem;
    }
}

@media (max-width: 800px) {
    .revenue {
        margin-left: 5rem;
    }
}

@media (max-width: 500px) {
    .revenue {
        margin-left: 3rem;
    }
}

@media (max-width: 350px) {
    .revenue {
        margin-left: 1.5rem;
    }
}