@media (max-width: 980px) {
    .flex-col-sm {
      flex-direction: column;
    }
  }
  
  
  .custom-chart {
    width: 369px !important;
    height: 369px !important;
  }
  
  
  .round-legend-label {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%; 
    margin-right: 5px; 
  }
  