.custom-scrollbar {
    overflow-x: auto;
    scrollbar-color: #2297a7 #f0f0f0;
}

.custom-pagination {
    margin-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    justify-content: start !important;
    padding-bottom: 0.7rem;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.popup-content {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    position: relative;
    width: 40%;
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

@media (amx-width: 500px) {
    .lead{
        margin-left: 0;
    }
}