.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    width: 100vw; /* Full width of the viewport */
    position: fixed; /* Ensure it stays in place */
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Optional: adds a semi-transparent background */
    z-index: 9999; /* Ensure it stays on top of other content */
  }
  
  .loading {
    text-align: center;
    color: #fff;
    z-index: 10000;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Optional: semi-transparent overlay */
  }
  