
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.middle-right-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
}

.left-section,
.col {
  width: 100%;
  gap: 100px;
}

.rectangular-box {
  width: 100%;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  margin-bottom: 20px;
  animation: fadeIn 0.5s ease forwards; 
}

.faq-box {
  max-height: 200px;
  overflow-y: auto;
}


.button {
  background-color: #ff6f61; 
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
  animation: slideIn 0.5s ease forwards; 
}


.button:hover {
  background-color: #ff8f81; 
}


@media (max-width: 768px) {
  .middle-right-section {
    flex-direction: column;
  }
}
.ro{
  display: flex;
  flex-direction: column;
}


.radio-label {
  display: flex;
  align-items: center;
}

.radio-label label {
  margin-right: 10px; 
}

.radio-label input[type="radio"] {
  width: 20px; 
  height: 20px; 
  margin-right: 5px;
}

.radio-label input[type="radio"] + span {
  color: rgb(0, 0, 0);
  margin-bottom: 10px !important; 
}
