.testi{
  --background: url(/src/utils/TestimonialBack.png);
  --bg-image-opacity: 1;
  position: relative;
  isolation: isolate;
  width: 100%;
  height: 120vh;
}
.testi::after{
  content: '';
  opacity: var(--bg-image-opacity);
  background: var(--background);
  background-position: center;
  background-size: cover;
  position: absolute;
  z-index: -1;
  inset: 0;
}