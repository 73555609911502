.Slide {
    margin-left: 5rem;
    animation: slide 2s ease-in-out;
}

@keyframes slide {
    0% {
        margin-left: -2rem;
    }

    100% {
        margin-left: 5rem;
    }
}


@media (max-width: 400px) {
    .custom-sidebar .custom-sidebar-item {
        margin-bottom: 0.5rem; /* Reduce space between items */
    }
}

@media (min-width: 1025px) {
    .custom-sidebar {
        width: 18%;
    }
}
