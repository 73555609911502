.NavBar{
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    height: 60px;
    align-items: center;
    flex-shrink: 0;
    background: #000000;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
}
.complogo{
    border-radius: 75px;
    width: 29px;
    height: 29px;
    cursor: pointer;
    box-shadow: 0px 4px 8px 4px rgba(255, 255, 255, 0.25);
}